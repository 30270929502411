import { Image, ImageProps, Box, keyframes } from "@chakra-ui/react";
import StoryModeModal, {
  StoryModeModalContext,
  withStoryModeModal,
} from "../StoryModeModal";
import { useDialogWithOptions } from "../../../Common/Dialog";
import { KYDStoryAsset } from "@common/interfaces/KYDEvent";
import { motion } from "framer-motion";

const gradientBorder = keyframes`
  0% { background-position: 0% 50%; }
  100% { background-position: 200% 50%; }
`;

interface EventImageProps extends Omit<ImageProps, "src"> {
  posterImage?: string;
  storyAssets: KYDStoryAsset[];
}

function EventImage({
  posterImage,
  storyAssets = [],
  ...props
}: EventImageProps) {
  const storyModeDialog = useDialogWithOptions(StoryModeModalContext);

  return (
    <>
      <StoryModeModal
        storyAssets={storyAssets}
        width={["95vw", 432]}
        height={768}
      />
      <Box
        position="relative"
        onClick={() => storyAssets.length && storyModeDialog.open()}
        cursor={storyAssets.length ? "pointer" : "default"}
      >
        {storyAssets.length > 0 ? (
          <Box position="absolute" pointerEvents="none">
            <motion.svg
              width="100%"
              height="100%"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <defs>
                <linearGradient id="rainbow" gradientUnits="userSpaceOnUse">
                  <stop offset="0%" stopColor="#FF6B6B" />
                  <stop offset="33%" stopColor="#4ECDC4" />
                  <stop offset="66%" stopColor="#FFD93D" />
                  <stop offset="100%" stopColor="#FF6B6B" />
                </linearGradient>
              </defs>
              <motion.rect
                width="100"
                height="100"
                fill="none"
                strokeWidth="2"
                initial={{
                  stroke: "white",
                  pathLength: 0,
                  opacity: 0,
                }}
                animate={{
                  pathLength: 1,
                  opacity: [0, 1, 1, 1],
                  stroke: ["white", "white", "url(#rainbow)", "url(#rainbow)"],
                }}
                transition={{
                  pathLength: {
                    duration: 2.0,
                    ease: [0.43, 0, 0.58, 1],
                  },
                  opacity: {
                    duration: 2.0,
                    times: [0, 0.25, 0.75, 1],
                    ease: "easeInOut",
                  },
                  stroke: {
                    delay: 1.8,
                    duration: 4,
                    ease: "easeInOut",
                  },
                }}
              />
              <animateTransform
                xlinkHref="#rainbow"
                attributeName="gradientTransform"
                type="rotate"
                from="0 50 50"
                to="360 50 50"
                dur="10s"
                repeatCount="indefinite"
              />
            </motion.svg>
          </Box>
        ) : null}
        <Image
          maxW="200px"
          maxH="200px"
          objectFit="cover"
          src={posterImage}
          {...props}
        />
      </Box>
    </>
  );
}

export default withStoryModeModal(EventImage);
