import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { useContext, useRef, useState } from "react";
import { DialogProvider, createDialogContext } from "../../../Common/Dialog"
import StoryView from "../StoryView";
import { Story } from 'react-insta-stories/dist/interfaces';
import { KYDStoryAsset } from "@common/interfaces/KYDEvent";

export const StoryModeModalContext = createDialogContext();

interface StoryModeModalProps {
  storyAssets: KYDStoryAsset[];
  width?: string | number | (string | number)[];
  height?: string | number;
}

const StoryModeModal = ({ 
  storyAssets,
  width = ["95vw", "100%"],
  height = "100vh" 
}: StoryModeModalProps) => {
  const { isOpen, close } = useContext(StoryModeModalContext);
  const [isDragging, setIsDragging] = useState(false);
  const [translateY, setTranslateY] = useState(0);
  const touchStart = useRef(0);
  const modalRef = useRef<HTMLDivElement>(null);

  const handleTouchStart = (e: React.TouchEvent | React.MouseEvent) => {
    const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;
    touchStart.current = clientY;
    setIsDragging(true);
  };

  const handleTouchMove = (e: React.TouchEvent | React.MouseEvent) => {
    if (!isDragging) return;
    const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;
    const delta = clientY - touchStart.current;
    if (delta > 0) {
      setTranslateY(delta);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    if (translateY > 150) {
      close();
    }
    setTranslateY(0);
  };

  const isVideo = (url: string) => {
    const videoExtensions = ['.mp4', '.webm'];
    return videoExtensions.some(ext => url.toLowerCase().endsWith(ext));
  };

  const stories: Story[] = storyAssets
    .sort((a, b) => a.position - b.position)
    .map(asset => ({
      url: asset.url,
      type: isVideo(asset.key) ? 'video' : 'image',
      duration: isVideo(asset.key) ? -1 : 5000,
    }));

  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      closeOnOverlayClick={true}
      size={["full", "md"]}
    >
      <ModalOverlay />
      <ModalContent
        ref={modalRef}
        pos="relative"
        my={[0, "auto"]}
        borderRadius={[0, "md"]}
        maxW={width}
        h={height}
        overflow="hidden"
        transform={`translateY(${translateY}px)`}
        transition={isDragging ? "none" : "transform 0.3s ease"}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onMouseDown={handleTouchStart}
        onMouseMove={handleTouchMove}
        onMouseUp={handleTouchEnd}
        onMouseLeave={handleTouchEnd}
      >
        <ModalCloseButton
          position="absolute"
          right={2}
          top={10}
          zIndex={99999}
          color="white"
          bg="rgba(0, 0, 0, 0.3)"
          borderRadius="full"
          _hover={{
            bg: "rgba(0, 0, 0, 0.5)"
          }}
        />
        <ModalBody p={0} textAlign="center" w="100%" h="100%">
          <Box 
            w="100%" 
            h="100%" 
            display="flex" 
            alignItems="center" 
            justifyContent="center"
          >
            <Box
              w="100%"
              h="100%"
              objectFit="contain"
              maxH="100vh"
              flex={1}
              display="flex"
              sx={{
                aspectRatio: "9/16"
              }}
            >
              <StoryView 
                stories={stories} 
                width="100%" 
                height="100%"
              />
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export const withStoryModeModal = <T extends object>(
  Component: React.ComponentType<T>
) => (props: T) => {
  return (
    <DialogProvider context={StoryModeModalContext}>
      <Component {...(props as T)} />
    </DialogProvider>
  );
};


export default StoryModeModal