import Stories from 'react-insta-stories';
import { Story } from 'react-insta-stories/dist/interfaces';
import { Box } from '@chakra-ui/react';

interface StoryViewProps {
  stories: Story[];
  width: string | number,
  height: string | number
}

const StoryView = ({ stories, width, height }: StoryViewProps) => {
  return (
    <Box position="relative">
      <Stories 
        stories={stories} 
        width={width} 
        height={height}
      />
    </Box>
  );
};

export default StoryView;
